import { createSlice } from '@reduxjs/toolkit';

const hintsModel = {
  hints: [],
};

export const hintsSlice = createSlice({
  name: 'hints',
  initialState: hintsModel,
  reducers: {
    setHints: (state, action) => {
      const {
        hints,
        unlockHintAfterIncorrectAnswer,
        hintsUnlockedNumber,
        unlockHintAfterDelay,
      } = action?.payload ?? {};

      return {
        ...state,
        hints: hints?.map((item, index) => ({
          title: item.title,
          content: item.content,
          unlocked:
            index < hintsUnlockedNumber || 
            (!unlockHintAfterIncorrectAnswer && !unlockHintAfterDelay),
          new: false,
          isOpen: false,
        })),
      };
    },
    unlockNewHint: (state) => {
      const hints = [...state.hints];
      const index = hints.findIndex((hint) => !hint.unlocked);
      if (index > -1) {
        hints[index].unlocked = true;
        hints[index].new = true;
      }
    },
    setCurrentHint: (state, action) => {
      const currentHintIndex = action.payload;
      const hints = [...state.hints];
      hints[currentHintIndex].new = false;
      hints.forEach((hint, index) => {
        index !== currentHintIndex
          ? (hint.isOpen = false)
          : (hint.isOpen = !hint.isOpen);
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { setHints, unlockNewHint, setCurrentHint } = hintsSlice.actions;

export default hintsSlice.reducer;

import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import reportWebVitals from 'reportWebVitals';
import store from './store';
import i18n from 'translation/i18n';

const App = lazy(() => import('components/App'));
const isModeStandalone = process.env.REACT_APP_MODE_VAR === 'standalone';

const persistor = persistStore(store);
persistor.purge();

const matomoInstance = !isModeStandalone
  ? createInstance({
    urlBase: 'https://stats.maskott.com/',
    siteId: 5,
  })
  : null;

const container = document.getElementById('root');
const root = createRoot(container);

const AppWithProviders = ({ useMatomo }) => {
  return useMatomo ? (
    <MatomoProvider value={matomoInstance}>
      <Suspense>
        <App />
      </Suspense>
    </MatomoProvider>
  ) : (
    <Suspense>
      <App />
    </Suspense>
  );
};

root.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <React.StrictMode>
          <AppWithProviders useMatomo={!isModeStandalone} />
        </React.StrictMode>
      </PersistGate>
    </Provider>
  </I18nextProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

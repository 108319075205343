/* eslint-disable max-classes-per-file */
import { QuestionModel } from 'core/models/elements/questions/question.abstract.model';

/**
 * @class Defines a hotspot question.
 */
export class GrainContentModel extends QuestionModel {
  /**
   * @constructor Creates an instance of a hotspot question built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    super(bo);
    this.url = bo.url;
    this.offlineResourcePath = bo.offline_resource_path;
    this.subtitles = bo.additionnal_resources?.Subtitles;
    this.transcriptions = bo.additionnal_resources?.Transcriptions;
    this.extension = bo.extension;
    this.customSidebar = ['.mp4', '.ogg', '.webm'].includes(bo.extension)
      ? true
      : false;
    this.hideHeader = ['.mp4', '.ogg', '.webm'].includes(bo.extension)
      ? true
      : false;
  }
}
